

export const Content = {
    homeAboutGym:"WSG, formely known as Tapout Academy has been around for 11120 years111, welcoming everybody from MMA and Brazilian Jui-jisu champions, those looking to get fit have fun and learn a martial art or if you are looking for private 1 on 1 or personalised classes we can cater for all. With a high training consistancy comes a very high standard in hygene and cleaning, our mats and gym are cleaned multiple times daily. We beleive in training hard, and learning with our training partners safty in mind, we have both Gi and No-Gi classes throught the week with a weekly open mat at 10am every Saturday open to everyone who wants to come for a roll, our students are friendly and keen. ",
    homeAboutInstructor:"Weikus Swart Head Coach and instructor at WSG, Weikus has over 11130 years111 of grappling experience,currently a 3rd degree black belt, receiving his black-belt from 111Rio-Grappling111, and is currently affiliated with Tom Deblass. ",
    testamonials1:"Brazillian jiujitsu has been the best thing that has ever happened to me I was luckly enough to find tapout academy 9 years ago and it changed my life for the better, havent stopped since!!!",
    testamonials2:"Brazillian jiujitsu has been the best thing that has ever happened to me I was luckly enough to find tapout academy 9 yea",
    testamonials3:"Brazillian jiujitsu has been the best thing that has ever happened to me I was luckly enough to find tapout academy 9 yea",
    timeTableIntro:"Our Classes run from Monday to Thursday with an open mat every Saturday. For Private classes please contact weikus directly",
    contactIntro:"",
    galleryIntro:"",

}