import { useState } from "react";

import Gallery from "./Gallery";

import "./Styles/AcheivmentsStyles.scss";

const GalleryDisplay = () => {
    return (
        <>
            <h1 className="GalleryHeader">Image Gallery</h1>

            <Gallery />

        </>
    )
}

export default GalleryDisplay;