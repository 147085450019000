
import "./Styles/HomeStyles.scss";
import {Content} from "./Contents";

import ImageSlide from "./ImageSlider";

import achievement from "./Images/achievement.png";
import sponserImg from "./Images/SponserBanner.jpg";

import {TimeTablePg} from "./TimeTablePg";
import {Contact} from "./Contact";

export const Home =() =>{
const aboutGym = Content.homeAboutGym;
const aboutWiek = Content.homeAboutInstructor;
    return(
        <div className="homeContainer">

        <div className="homeContents">
  
        <div className="homeSlider">


        <ImageSlide></ImageSlide>

        </div>

        <div className="homeGymAbout">
        <div className="homeAboutGym">
                        <a href="TimeTablePg">
 <h3>Welcome to all</h3>
                            <h6>WSG is the home of the wolf pack</h6>
                            <p>If you want to get fit while learning Brazilian JiuJitsu.  </p>
                            <p>Or compete we have a high level competitive Brazilian JiuJistu team. </p>
</a>

</div>
<br></br>
<hr className="hrDotted"></hr>


</div>


<div className="instructorsBG">
<div className="homeAboutInstructors">
<a href="Contact">
<h3>Wiekus Swart</h3>
<h4>Head instructor </h4>
<p>Wiekus has been involved in the Brazilian JiuJistu scene in South Africa from the start and now runs one of the top competitive teams in South Africa.</p>

<p>Wiekus is a 3rd degree black belt under Tom Deblass.</p>
</a>
</div>
</div>
<br></br>
<br></br>
      <TimeTablePg/>
      <br></br>     
      <br></br>
      <Contact/>
        </div>
        </div>
    )
} 