
import React, { useState } from 'react';
import "./Styles/GalleryDisplayStyle.scss"
import imgBelt from "./Images/belt.png";
import imgBelt1 from "./Images/belt1.png";
import imgBelt2 from "./Images/belt2.png";
import imgBelt3 from "./Images/belt3.png";
import imgBelt4 from "./Images/belt4.png";

import Img1 from "./Images/2.jpg";
import Img2 from "./Images/15.jpg";
import Img3 from "./Images/comp9.jpg";
import Img4 from "./Images/3.jpg";
import Img5 from "./Images/6.jpg";
import Img6 from "./Images/7.jpg";
import Img7 from "./Images/8.jpg";
import Img8 from "./Images/10.jpg";
import Img9 from "./Images/11.jpg";
import Img10 from "./Images/12.jpg";
import Img11 from "./Images/13.jpg";

import Img1a from "./Images2023/1.jpg";
import Img2a from "./Images2023/2.jpg";
import Img3a from "./Images2023/5.jpg";
import Img4a from "./Images2023/3.jpg";
import Img5a from "./Images2023/6.jpg";
import Img6a from "./Images2023/7.jpg";
import Img7a from "./Images2023/8.jpg";
import Img8a from "./Images2023/10.jpg";
import Img9a from "./Images2023/11.jpg";
import Img10a from "./Images2023/9.jpg";
import Img11a from "./Images2023/4.jpg";
import Img12a from "./Images2023/12.jpg";
import Img13a from "./Images2023/13.jpg";
import Img14a from "./Images2023/15.jpg";
import Img15a from "./Images2023/16.jpg";
import Img16a from "./Images2023/17.jpg";
import Img17a from "./Images2023/18.jpg";
import Img18a from "./Images2023/19.jpg";
import Img19a from "./Images2023/20.jpg";
import Img20a from "./Images2023/21.jpg";
import Img21a from "./Images2023/4.jpg";


import Img1b from "./Images2023/22.jpg";
import Img2b from "./Images2023/23.jpg";
import Img3b from "./Images2023/24.jpg";
import Img4b from "./Images2023/25.jpg";
import Img5b from "./Images2023/26.jpg";
import Img6b from "./Images2023/27.jpg";
import Img7b from "./Images2023/28.jpg";
import Img8b from "./Images2023/29.jpg";
import Img9b from "./Images2023/30.jpg";
import Img10b from "./Images2023/31.jpg";

import Img1c from "./Images2024/1.jpg";
import Img2c from "./Images2024/2.jpg";
import Img3c from "./Images2024/3.jpg";
import Img4c from "./Images2024/4.jpg";
import Img5c from "./Images2024/5.jpg";
import Img6c from "./Images2024/6.jpg";
import Img7c from "./Images2024/7.jpg";
import Img8c from "./Images2024/8.jpg";
import Img9c from "./Images2024/9.jpg";
import Img10c from "./Images2024/10.jpg";
import Img11c from "./Images2024/11.jpg";
import Img12c from "./Images2024/12.jpg";
import Img13c from "./Images2024/13.jpg";
import Img14c from "./Images2024/14.jpg";
import Img15c from "./Images2024/15.jpg";
import Img16c from "./Images2024/16.jpg";
import Img17c from "./Images2024/17.jpg";

const Gallery = () => {

    let isExpanded = false;
    const [isGalleryNavExpanded, SetIsGalleryNavExpanded] = useState("main-GallerynavBod ");
    const [isGalleryNavExpandedMNI, SetIsGalleryNavExpandedMNI] = useState("main-GallerynavItem");
    const [isGalleryNavExpandedMNIS, SetIsGalleryNavExpandedMNIS] = useState("main-GallerynavItems");
    const [GallerynavBarClassName, SetGalleryNavBarBgDisplay] = useState("main-Galleryheader");



    function ToggleGalleryHamburger() {

        isExpanded = !isExpanded;
        if (isExpanded === true) {
            SetIsGalleryNavExpanded("mobile-Gallerynav ");
            SetIsGalleryNavExpandedMNI("mobile-GallerynavItem");
            SetIsGalleryNavExpandedMNIS("mobile-GallerynavItems");
        }
        else if (isExpanded === false) {
            SetIsGalleryNavExpanded("main-GallerynavBod");
        }
    }



    function ToggleGalleryHamburgerOff() {

        isExpanded = false;
        SetIsGalleryNavExpanded("main-GallerynavBod");
        SetIsGalleryNavExpandedMNI("main-GallerynavItem");
        SetIsGalleryNavExpandedMNIS("main-GallerynavItems");
    }

    function ChangeNavBg() {

        var scrollValue = window.scrollY;

        if (scrollValue < 200) {
            SetGalleryNavBarBgDisplay("main-header-bgHidden");
        }
        else {
            SetGalleryNavBarBgDisplay("main-header-bgDisplay");
        }

    }

    let data1 = [
        {
            id: 1,
            imgSrc: Img1,
        },
        {
            id: 2,
            imgSrc: Img2,
        },
        {
            id: 3,
            imgSrc: Img3,
        },
        {
            id: 4,
            imgSrc: Img4,
        },
        {
            id: 5,
            imgSrc: Img5,
        },
        {
            id: 6,
            imgSrc: Img6,
        },
        {
            id: 7,
            imgSrc: Img7,
        },
        {
            id: 8,
            imgSrc: Img8,
        },
        {
            id: 9,
            imgSrc: Img9,
        },
        {
            id: 10,
            imgSrc: Img10,
        },
        {
            id: 11,
            imgSrc: Img11,
        },

    ]

    let data2 = [
        {
            id: 1,
            imgSrc: Img1a,
        },
        {
            id: 2,
            imgSrc: Img2a,
        },
        {
            id: 3,
            imgSrc: Img3a,
        },
        {
            id: 4,
            imgSrc: Img4a,
        },
        {
            id: 5,
            imgSrc: Img5a,
        },
        {
            id: 6,
            imgSrc: Img6a,
        },
        {
            id: 7,
            imgSrc: Img7a,
        },
        {
            id: 8,
            imgSrc: Img8a,
        },
        {
            id: 9,
            imgSrc: Img9a,
        },
        {
            id: 10,
            imgSrc: Img10a,
        },
        {
            id: 11,
            imgSrc: Img11a,
        },
        {
            id: 12,
            imgSrc: Img12a,
        },
        {
            id: 13,
            imgSrc: Img13a,
        },
        {
            id: 14,
            imgSrc: Img14a,
        },
        {
            id: 15,
            imgSrc: Img15a,
        },
        {
            id: 16,
            imgSrc: Img16a,
        },
        {
            id: 17,
            imgSrc: Img17a,
        },
        {
            id: 18,
            imgSrc: Img18a,
        },
        {
            id: 19,
            imgSrc: Img19a,
        },
        {
            id: 20,
            imgSrc: Img20a,
        },
        {
            id: 21,
            imgSrc: Img21a,
        },

    ]

    let data3 = [
        {
            id: 1,
            imgSrc: Img1b,
        },
        {
            id: 2,
            imgSrc: Img2b,
        },
        {
            id: 3,
            imgSrc: Img3b,
        },
        {
            id: 4,
            imgSrc: Img4b,
        },
        {
            id: 5,
            imgSrc: Img5b,
        },
        {
            id: 6,
            imgSrc: Img6b,
        },
        {
            id: 7,
            imgSrc: Img7b,
        },
        {
            id: 8,
            imgSrc: Img8b,
        },
        {
            id: 9,
            imgSrc: Img9b,
        },
        {
            id: 10,
            imgSrc: Img10b,
        },

    ]

    let data4 = [
        {
            id: 1,
            imgSrc: Img1c,
        },
        {
            id: 2,
            imgSrc: Img2c,
        },
        {
            id: 3,
            imgSrc: Img3c,
        },
        {
            id: 4,
            imgSrc: Img4c,
        },
        {
            id: 5,
            imgSrc: Img5c,
        },
        {
            id: 6,
            imgSrc: Img6c,
        },
        {
            id: 7,
            imgSrc: Img7c,
        },
        {
            id: 8,
            imgSrc: Img8c,
        },
        {
            id: 9,
            imgSrc: Img9c,
        },
        {
            id: 10,
            imgSrc: Img10c,
        },
        {
            id: 11,
            imgSrc: Img11c,
        },
        {
            id: 12,
            imgSrc: Img12c,
        },
        {
            id: 13,
            imgSrc: Img13c,
        },
        {
            id: 14,
            imgSrc: Img14c,
        },
        {
            id: 15,
            imgSrc: Img12c,
        },
        {
            id: 16,
            imgSrc: Img13c,
        },
        {
            id: 17,
            imgSrc: Img14c,
        },

    ]

    const [model, setModel] = useState(false);
    const [tempImgSrc, setTempImgSrc] = useState('');
    const [galleryTab, setGalleryTab] = useState(data1);

    const getImg = (imgSrc) => {
        setTempImgSrc(imgSrc);
        setModel(true);
    }
    return (
        <>
            <ul className={isGalleryNavExpandedMNIS} onClick={ToggleGalleryHamburgerOff}>
                <li className={isGalleryNavExpandedMNI}>
                    <a onClick={() => { setGalleryTab(data1) }}><h4 className="galleryTxt">Gallery#1</h4></a>
                    <img className='GalleryUnderline' src={imgBelt1} />
                </li>
                <li className={isGalleryNavExpandedMNI}>
                    <a onClick={() => { setGalleryTab(data2) }}><h4 className="galleryTxt">Gallery#2</h4></a>
                    <img className='GalleryUnderline' src={imgBelt2} />
                </li>

                <li className={isGalleryNavExpandedMNI}>
                    <a onClick={() => { setGalleryTab(data3) }}><h4 className="galleryTxt">Gallery#3</h4></a>
                    <img className='GalleryUnderline' src={imgBelt3} />
                </li>
                <li className={isGalleryNavExpandedMNI}>
                    <a onClick={() => { setGalleryTab(data4) }}><h4 className="galleryTxt">Gallery#4</h4></a>
                    <img className='GalleryUnderline' src={imgBelt4} />
                </li>
                <li className={isGalleryNavExpandedMNI}>
                    <a onClick={() => { setGalleryTab(data4) }}><h4 className="galleryTxt">Gallery#5</h4></a>
                    <img className='GalleryUnderline' src={imgBelt} />
                </li>
            </ul>

            <div className={model ? 'model open' : 'model'}>
                <img src={tempImgSrc} />
                <svg width="10%" height="10%" viewBox="0 0 24 24" fill="none" onClick={() => setModel(false)} >
                    <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>

            <div className='gallery'>
                {galleryTab.map((item, index) => {
                    return (
                        <div key={index} className='pics' onClick={() => { getImg(item.imgSrc) }}>
                            <img src={item.imgSrc} style={{ width: '100%' }} alt="Image" />
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default Gallery;