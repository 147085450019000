
export const SlideData=[
    {
        image:"oneA"
    },
    {
        image:"one"
    },
    {
        image:"two"
    },
    {
        image:"three"
    },
    {
        image:"four"
    },
    {
        image:"five"
    },    
    {
        image:"six"
    }
]

export const GallerySlideData=[
    {
        image:"gone"
    },
    {
        image:"gtwo"
    },
    {
        image:"gthree"
    },
    {
        image:"gfour"
    },
    {
        image:"gfive"
    },    
    {
        image:"gsix"
    }
]